<template>
  <div class="a-page">
    <div v-if="pending"><BaseSpinner brand /></div>
    <div v-else>
      <RouterLink
        :to="{ name: 'mentor-student', params: { id: $route.params.id } }"
        class="a-page__back-link mb-l">
        Ко всем предметам
      </RouterLink>

      <div v-if="student && activity">
        <div class="row">
          <div class="col-sm-6 col-xs-12 mb-xxl">
            <h1 class="text-size-h1 text-bold">
              {{ activity.title }}
            </h1>
            <div class="text-size-s color-meta mb-xs">{{ stageText }}</div>
            <div>Детализация результатов</div>
          </div>
          <div class="col-sm-6 col-xs-12 mb-m">
            <div class="a-page__user">
              <BaseAvatar
                :gender="student.sex"
                :image="student.avatar"
                class="a-page__user-avatar" />
              <div>
                <div>{{ student.first_name }} {{ student.last_name }}</div>
                <div
                  v-if="studentTrack"
                  class="text-size-xs">
                  {{ studentTrack }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Профили -->
        <div
          v-if="activity.profiles && activity.profiles.length > 1"
          class="mb-m">
          <div class="text-size-h3 text-bold">Список профилей:</div>
          <p class="text-size-s mb-m">
            Список профилей в которых учитывается это задание
          </p>
          <AppTag
            v-for="profile in activity.profiles"
            :key="profile.id"
            theme="inverse"
            tag="router-link"
            :to="{
              name: 'mentor-profile',
              params: {
                id: profile.id,
              },
            }"
            class="a-page__tag text-size-s">
            {{ profile.title }}
          </AppTag>
        </div>

        <BaseAlert
          v-if="isJuniorStudent"
          class="mt-m mb-l radius-m">
          Вы&nbsp;сможете пройти/посмотреть задания после окончания отборочного
          этапа в&nbsp;образовательном блоке.
        </BaseAlert>

        <EducationBlocks
          v-if="currentEduBlocks.length"
          :blocks="currentEduBlocks"
          class="mt-l mb-l" />

        <!-- <div
          v-else-if="activity"
          class="row flex-middle-md mb-m">
          <div class="col-md-3 col-sm-3 col-xs-12">
            <EnrollButton
              theme="primary"
              :block="true"
              :activity="activity"
              :disabled="!mentorCanEnroll"
              >Открыть задание</EnrollButton
            >
          </div>
          <div
            v-if="!mentorCanEnroll"
            class="col-md-5 col-sm-6 col-xs-12">
            <div
              v-if="mentorEnrollmentDate"
              class="text-size-s">
              Возможность просмотреть это задание появится после
              <span> {{ mentorEnrollmentDate }}</span
              >.
            </div>
            <div v-else>Пока нет возможности пройти это задание</div>
          </div>
        </div> -->
        <!-- Попытки -->
        <div v-if="list && list.length">
          <h1 class="text-size-h3 text-bold mb-m">Список попыток</h1>
          <div
            v-for="attempt in list"
            :key="attempt.id"
            class="mb-xl">
            <div class="text-bold">
              {{ attempt.title || "Без названия" }}:
              <span v-if="attempt.resultScores >= 0">
                {{ formatedScores(attempt.resultScores) }}</span
              >
            </div>
            <div class="mb-m">
              <span class="text-size-s">{{
                attempt.formatedStart || "Нет даты начала"
              }}</span>
              &mdash;
              <span class="text-size-s">{{
                attempt.formatedEnd || "Нет даты завершения"
              }}</span>
            </div>

            <div v-if="attempt.end_at && isYandex">
              <EnrollButton
                theme="primary"
                :activity="activity"
                :attempt-id="attempt.id"
                :disabled="!allowEnrol"
                >Открыть задание</EnrollButton
              >
              <div class="text-size-s mt-s">
                Открыть это задание можно только после даты завершения попытки
              </div>
            </div>

            <div v-if="attempt.tasks && attempt.tasks.length">
              <div
                v-for="task in attempt.tasks"
                :key="task.id"
                class="a-page__panel">
                <div class="a-page__panel-title">
                  <div class="color-meta text-size-xs text-bold mb-s">
                    #{{ task.lesson_id }}. Задание {{ task.position }}
                  </div>
                  <div
                    v-if="task.tags"
                    class="text-size-s">
                    <span
                      v-for="(tag, idx) in task.tags"
                      :key="idx">
                      <AppTag v-if="tag">{{ tag }}</AppTag>
                    </span>
                  </div>
                  <div v-else>Тематика не определена</div>
                </div>
                <div class="a-page__panel-score text-bold">
                  {{ scoreForTasks[task.id] || 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <!-- Если это внешняя активность без попыток  -->
          <div v-if="activity.external_url && list">
            <p class="mb-s">
              У этого задания отсутствует детализация результатов
            </p>
          </div>
        </div>

        <!-- Ученики в этой же активности -->
        <div v-if="otherStudents.length">
          <div class="text-bold mb-s">Результаты других учеников:</div>

          <div class="row">
            <div
              v-for="item in otherStudents"
              :key="item.id"
              class="col-sm-6 col-xs-12">
              <div
                class="panel a-page__student"
                tabindex="0"
                @keydown.enter="goOtherStudent(item.id)"
                @click="goOtherStudent(item.id)">
                <BaseAvatar
                  :gender="item.sex"
                  :image="item.avatar"
                  class="a-page__student-avatar" />
                <div>
                  <div class="text-size-h4 text-bold">
                    {{ item.first_name }} {{ item.last_name }}
                  </div>
                  <div
                    v-if="item.gradeText"
                    class="text-size-xs color-meta">
                    {{ item.gradeText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @page MentorUserActivity
 * Детализация результатов ученика
 */
import { request } from "@/services/api";
import dayjs from "@/plugins/dayjs";
import { numCases } from "@/utils";
import AppTag from "@/components/AppTag";
import EnrollButton from "@/components/EnrollButton.vue";
import { getEducationBlocks } from "@/services/shared/eductions";
import EducationBlocks from "@/components/education/EducationBlocks.vue";
import {
  FINAL_STAGE,
  INDIVIDUAL_STAGE,
  TEAM_STAGE,
  STUDENT_ALIAS,
  JUNIOR_ALIAS,
  PARTICIPANT_ROLE,
} from "@/constants";

const SCHEDULE_FORMAT = "dddd D MMMM HH:mm (UTC Z)";

export default {
  name: "MentorUserActivity",
  components: {
    AppTag,
    EnrollButton,
    EducationBlocks,
  },
  metaInfo() {
    return {
      title: "Информация о задании",
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  data() {
    return {
      student: null,
      activity: null,
      pending: false,
      otherStudents: [],
      eduBlocks: [],
    };
  },
  computed: {
    syncTimeStamp() {
      return this.$store.state.syncTimeStamp;
    },
    // Этап
    stageText() {
      switch (this.activity.stage) {
        case FINAL_STAGE:
          return "Финал";
        case INDIVIDUAL_STAGE:
          return "Индивидуальный этап";
        case TEAM_STAGE:
          return "Командный этап";

        default:
          return "";
      }
    },
    studentTrack() {
      const tracks = this.$store.state.tracks;
      if (tracks[this.student.track_id]?.alias !== STUDENT_ALIAS)
        return `${this.student.grade} класс`;
      else return "";
    },
    // Возвращает баллы для каждой задачи
    scoreForTasks() {
      const { student } = this;
      if (!student || !student.task_scores) return {};

      return this.student.task_scores.reduce((acc, item) => {
        acc[item.task_id] = item.score;
        return acc;
      }, {});
    },
    // Возвращает список задач и общий бал в попытке
    dataForAttempts() {
      // eslint-disable-next-line no-useless-escape
      const re = /\[(.[^\]\[]*)\]/;
      return this.activity.attempts.reduce((acc, item) => {
        const tasks = item.lessons
          .map((lesson) => lesson.tasks)
          .flat()
          .filter((n) => {
            return n.description !== "Урок сгенерирован роботом ;)";
          });
        let score = 0;
        tasks.forEach((task) => {
          const match = re.exec(task.description);
          const title = match && match[1];
          if (!task.tags?.length && title?.length) {
            task.tags = match[1].split(";").filter(Boolean);
          }
          const taskScore = this.scoreForTasks[task.id];
          if (taskScore) {
            score = score + taskScore;
          }
        });

        acc[item.id] = {
          tasks: tasks.sort((a, b) => a.position - b.position),
          score,
        };
        return acc;
      }, {});
    },
    list() {
      const { student, activity, dataForAttempts, syncTimeStamp } = this;
      if (!student || !activity || !Array.isArray(activity?.attempts)) {
        return;
      }
      // убираем попытки про "Общая информация"
      const list = activity.attempts.filter((item) => {
        return item.title !== "Общая информация";
      });
      return list.map((attempt) => {
        if (dataForAttempts[attempt.id]) {
          return {
            ...attempt,
            tasks: dataForAttempts[attempt.id].tasks,
            resultScores: dataForAttempts[attempt.id].score || 0,
            formatedStart:
              attempt.start_at &&
              dayjs(attempt.start_at).format(SCHEDULE_FORMAT),
            formatedEnd:
              attempt.end_at && dayjs(attempt.end_at).format(SCHEDULE_FORMAT),
            allowEnrol: attempt.end_at
              ? dayjs(syncTimeStamp).isAfter(dayjs(attempt.end_at))
              : false,
          };
        }

        return attempt;
      });
    },
    isJuniorStudent() {
      const tracks = this.$store.state.tracks;
      const track = tracks[this.student?.track_id];
      return track?.alias === JUNIOR_ALIAS;
    },
    isYandex() {
      return !!this.activity?.yacontest;
    },
    currentEduBlocks() {
      const { student, activity, eduBlocks } = this;
      if (!student) return eduBlocks;
      let profile_ids = activity.profiles?.map(({ id }) => id) || [];
      const user_p = student?.profiles?.map(({ id }) => id) || [];

      if (user_p) {
        profile_ids = profile_ids.filter((id) => user_p.includes(id));
      }

      return eduBlocks.filter((n) => {
        return n.profiles && n.profiles.some((id) => profile_ids.includes(id));
      });
    },
  },
  watch: {
    "$route.params.id": {
      handler(val) {
        if (val) {
          this.getCurrentUser();
          this.getOtherStudents();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    formatedScores(scores = 0) {
      return `${scores} ${numCases(["балл", "балла", "баллов"], scores)}`;
    },
    async init() {
      this.pending = true;
      try {
        const { data } = await request({
          method: "GET",
          url: `activity/${this.$route.params.activity_id}`,
        });

        this.activity = data;
        this.getEducationBlocks();
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить данные о предмете",
          message: error.message,
        });
      }
      this.pending = false;
    },
    async getCurrentUser() {
      try {
        const { data } = await request({
          method: "GET",
          url: `mentor/students/${this.$route.params.id}`,
        });
        this.student = data;
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить данные об ученике",
          message: error.message,
        });
      }
    },
    async getOtherStudents() {
      try {
        const { data } = await request({
          method: "GET",
          url: "mentor/students",
          params: {
            activity: this.$route.params.activity_id,
          },
        });

        const tracks = this.$store.state.tracks;
        this.otherStudents = data.items
          .filter(({ id }) => id !== Number(this.$route.params.id))
          .map((student) => {
            const gradeText =
              tracks[student.track_id]?.alias !== STUDENT_ALIAS
                ? `${student.grade} класс`
                : "";
            return {
              ...student,
              gradeText,
            };
          });
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить данные о других учениках",
          message: error.message,
        });
      }
    },
    goOtherStudent(studentId) {
      this.$router.push({
        name: "mentor-student-activity",
        params: {
          activity_id: this.$route.params.activity_id,
          id: studentId,
        },
      });
    },
    async getEducationBlocks() {
      const { activity } = this;
      let ids = activity?.profiles?.map((n) => n.id);
      try {
        const { data } = await getEducationBlocks({
          p_id: ids,
          t_id: Object.keys(this.$store.state.tracks),
          roles: PARTICIPANT_ROLE,
          limit: 100,
          offset: 0,
        });
        this.eduBlocks = data;
      } catch (error) {
        //ignore
      }
    },
  },
};
</script>

<style lang="less" scoped>
.a-page {
  &__back-link {
    text-decoration: none;
    color: @deep-blue;
    padding-left: 20px;
    position: relative;
    transition: color 0.3s;
    display: inline-block;

    &::before {
      content: "";
      width: 7px;
      height: 7px;
      border-left: 1px solid;
      border-bottom: 1px solid;
      transform: rotate(45deg);
      position: absolute;
      left: 2px;
      top: 7px;
      transition: border-color 0.3s;
    }

    &:hover {
      color: @link-blue;
    }
  }
  &__user {
    display: flex;
    align-items: center;

    &-avatar {
      width: 40px;
      margin-right: 11px;
    }
  }

  &__panel {
    padding: 16px 24px;
    margin-bottom: 6px;
    background-color: #fff;
    box-shadow: 0px 0px 4px #b7deff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .a-tag {
      display: inline-block;
      margin-right: 5px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__tag {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  &__student {
    display: flex;
    cursor: pointer;
    align-items: flex-start;
    height: 100%;

    &-avatar {
      flex: 0 0 40px;
      margin-right: 15px;
    }
  }
}
</style>
